import {AdminLayout,LoginLayout} from '../layouts';
import {HomeAdmin} from '../pages/Admin';
import {LoginAdmin,ForgotAdmin, ResetAdmin} from '../pages/Admin/LoginAdmin/'
import {ServicesLizenList,ServicesLizenCreateEditShow} from  '../pages/Admin/ServicesAdmin/Lizen';
import { ServicesLizenBilling } from '../pages/Admin/ServicesAdmin/Lizen/ServicesLizenBilling';
import { ServicesOrionBilling } from '../pages/Admin/ServicesAdmin/Orion/ServicesOrionBilling';
import ServicesOrionCreateEditShow  from '../pages/Admin/ServicesAdmin/Orion/ServicesOrionCreateEditShow';
import  ServicesOrionList from '../pages/Admin/ServicesAdmin/Orion/ServicesOrionList';
import {TipoCoberturaOrionList, 
    TipoCoberturaOrionCreateEditShow,
    TipoEstudioOrionList, 
    TipoEstudioOrionCreateEditShow,
    AseguradoraOrionList,
    AseguradoraOrionCreateEditShow,
    ClinicaOrionList,
    ClinicaOrionCreateEditShow,
    BeneficiarioOrionList,
    BeneficiarioOrionCreateEditShow
} from  '../pages/Admin/AbmAdmin/Orion';

//import { ProveedorLizenCreateEditShow, ProveedorLizenList } from '../pages/Admin/AbmAdmin/Lizen';
import {ProveedorLizenCreateEditShow} from '../pages/Admin/AbmAdmin/Lizen/ProveedorLizenCreateEditShow'
import {ProveedorLizenList,} from '../pages/Admin/AbmAdmin/Lizen/ProveedorLizenList'


const routesAdmin = [
    
    {
        path:"/",
        layout: LoginLayout,
        component: LoginAdmin,
        exact: true
    },
    {
        path:"/admin",
        layout: AdminLayout,
        component: HomeAdmin,
        exact: true
    },
    {
        path:"/login",
        layout: LoginLayout,
        component: LoginAdmin,
        exact: true
    }
     ,
    {
        path:"/admin/servicios_lizen/:type_action/id",
        layout: AdminLayout,
        component: ServicesLizenCreateEditShow,
        exact: true
    },
    {
        path:"/admin/servicios_orion/:type_action/id",
        layout: AdminLayout,
        component: ServicesOrionCreateEditShow,
        exact: true
    } ,
    {
        path:"/recovery",
        layout: LoginLayout,
        component: ForgotAdmin,
        exact: true
    },
    {
        path:"/change-password/:hash",
        layout: LoginLayout,
        component: ResetAdmin,
        exact: true
    },
    {
        path:"/admin/servicios_lizen/listado",
        layout: AdminLayout,
        component: ServicesLizenList,
        exact: true
    }  , 
    {
        path:"/admin/servicios_orion/listado",
        layout: AdminLayout,
        component: ServicesOrionList,
        exact: true
    },
    {
        path:"/admin/servicios_lizen/:type_action/id/:id",
        layout: AdminLayout,
        component: ServicesLizenCreateEditShow,
        exact: true
    },
    {
        path:"/admin/servicios_orion/:type_action/id/:id",
        layout: AdminLayout,
        component: ServicesOrionCreateEditShow,
        exact: true
    },
    {
        path:"/admin/facturacion_lizen/id/:id",
        layout: AdminLayout,
        component: ServicesLizenBilling,
        exact: true
    },
    {
        path:"/admin/facturacion_lizen/crear",
        layout: AdminLayout,
        component: ServicesLizenBilling,
        exact: true
    },
    {
        path:"/admin/facturacion_orion/id/:id",
        layout: AdminLayout,
        component: ServicesOrionBilling,
        exact: true
    },
    {
        path:"/admin/facturacion_orion/crear",
        layout: AdminLayout,
        component: ServicesOrionBilling,
        exact: true
    },
    {
        path:"/admin/tipo_cobertura_orion/crear",
        layout: AdminLayout,
        component: TipoCoberturaOrionCreateEditShow,
        exact: true
    },
    {
        path:"/admin/tipo_cobertura_orion/:type_action/id/:id",
        layout: AdminLayout,
        component: TipoCoberturaOrionCreateEditShow,
        exact: true
    },
    {
        path:"/admin/tipo_cobertura_orion/listado",
        layout: AdminLayout,
        component: TipoCoberturaOrionList,
        exact: true
    } ,
    {
        path:"/admin/tipo_estudio_orion/crear",
        layout: AdminLayout,
        component: TipoEstudioOrionCreateEditShow,
        exact: true
    },
    {
        path:"/admin/tipo_estudio_orion/:type_action/id/:id",
        layout: AdminLayout,
        component: TipoEstudioOrionCreateEditShow,
        exact: true
    },
    {
        path:"/admin/tipo_estudio_orion/listado",
        layout: AdminLayout,
        component: TipoEstudioOrionList,
        exact: true
    } ,
    {
        path:"/admin/aseguradora_orion/crear",
        layout: AdminLayout,
        component: AseguradoraOrionCreateEditShow,
        exact: true
    },
    {
        path:"/admin/aseguradora_orion/:type_action/id/:id",
        layout: AdminLayout,
        component: AseguradoraOrionCreateEditShow,
        exact: true
    },
    {
        path:"/admin/aseguradora_orion/listado",
        layout: AdminLayout,
        component: AseguradoraOrionList,
        exact: true
    } ,
    {
        path:"/admin/clinica_orion/crear",
        layout: AdminLayout,
        component: ClinicaOrionCreateEditShow,
        exact: true
    },
    {
        path:"/admin/clinica_orion/:type_action/id/:id",
        layout: AdminLayout,
        component: ClinicaOrionCreateEditShow,
        exact: true
    },
    {
        path:"/admin/clinica_orion/listado",
        layout: AdminLayout,
        component: ClinicaOrionList,
        exact: true
    } ,
    {
        path:"/admin/beneficiario_orion/crear",
        layout: AdminLayout,
        component: BeneficiarioOrionCreateEditShow,
        exact: true
    },
    {
        path:"/admin/beneficiario_orion/:type_action/id/:id",
        layout: AdminLayout,
        component: BeneficiarioOrionCreateEditShow,
        exact: true
    },
    {
        path:"/admin/beneficiario_orion/listado",
        layout: AdminLayout,
        component: BeneficiarioOrionList,
        exact: true
    } ,
    {
        path:"/admin/proveedor_lizen/crear",
        layout: AdminLayout,
        component: ProveedorLizenCreateEditShow,
        exact: true
    },
    {
        path:"/admin/proveedor_lizen/:type_action/id/:id",
        layout: AdminLayout,
        component: ProveedorLizenCreateEditShow,
        exact: true
    },
    {
        path:"/admin/proveedor_lizen/listado",
        layout: AdminLayout,
        component: ProveedorLizenList,
        exact: true
    } 
]

export default routesAdmin;