import React, { div, useEffect, useState } from 'react'
import { Header,Grid,Form,Segment ,Dropdown,Button,Icon, Label,List} from 'semantic-ui-react'
import "./ServicesAdmin.scss"
import {toast} from 'react-toastify'
import {Redirect, Link, BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { getServicioLizen } from '../../../../api/Lizen/servicio';
import {
	getFacturacionLizen,
	addFacturacionLizen,
	editFacturacionLizen,
	getLegajoLizen,
	fetchUploadFile,
	createLizenLegajo,
	deleteLegajo,
	getFacturaCliente,
	createLizenFactCliente, deleteFactCliente
} from '../../../../api/Lizen/facturacion';
import {useFormik} from 'formik'
import * as Yup from 'yup'
import { useAuth} from '../../../../hooks'
import {BASE_API} from '../../../../utils/constantes'
import "./ServicesAdmin.scss"
import { DropzoneComponent } from '../../../../components/Common/Dropzone/DropzoneComponent';
import { ModalBasic } from '../../../../components/Common';
import {getGroup} from "../../../../components/Common";

export  function ServicesLizenBilling(props) {
	const hiddenFileInput = React.useRef(null);
	const hiddenFileInputFactCliente = React.useRef(null);
	const hiddenFileInputLegajo = React.useRef(null);
	const {auth} = useAuth()
	const {history} = props;
	const {id} = props.match.params
	const isAddMode = !id;
	const [fileFactCliente, setFileFactCliente] = useState(null);
	const [fileFacturacion, setFileFacturacion] = useState(null);
	const [filesLegajo, setFilesLegajo] = useState([]);
	const [servicio,setServicio] = React.useState({})
	const [facturacion,setFacturacion] = React.useState({})
	const [legajos,setLegajos] = React.useState({})
	
	const [showModal,setShowModal] = useState(false)
	const [contentModal,setContentModal] = useState(null)
    const [titleModal,setTitleModal] = useState(null)
    const [dataModal,setDataModal] = useState(null)
	const fetchLegajo = async () =>{
		const legajos = await getLegajoLizen(`factura=${id}`)
		
		setLegajos(legajos)
		setFilesLegajo(legajos.results)
	}

	const fetchFactCliente = async (idAseguradora) =>{
		const fact_cliente = await getFacturaCliente(`${idAseguradora}`)
		if(fact_cliente !== null) setFileFactCliente(fact_cliente)

	}

	useEffect(()=>{
		
		const fetchServicio = async () =>{
			const servicioRes = await getServicioLizen(`${history.location.state.servicio_id}`)
			setServicio(servicioRes)
			return servicio
		} 
		fetchServicio()
		if(!isAddMode){
			const fetchFacturacion = async () =>{
				const facturacion = await getFacturacionLizen(`${id}`)
				
				setFacturacion(facturacion)
				if(facturacion.file_factura !== null) setFileFacturacion(facturacion?.file_factura)
				
			} 
			fetchFacturacion()
			
			fetchLegajo()

		}
		
    },[])

	useEffect(() => {
		if(servicio && servicio?.aseguradora)
		fetchFactCliente(servicio?.aseguradora.aseguradora   || null)
	}, [servicio]);

	const formik = useFormik({
		initialValues:initialValues(auth,servicio,facturacion),
		validationSchema:Yup.object(validationSchema()),
		onSubmit:async (formValue)=>{
			 if (isAddMode){
				const response = await addFacturacionLizen(formValue,servicio)
				try {
					
					if(response.status !== undefined && response.status === "error" ){
						toast.error(`Error en el sistema: ${JSON.stringify(response.message)}`)
					}else{
						
					
						    toast.success("Se guardó exitosamente.",{
							onClose: () => {
								history.push(`/admin/servicios_lizen/listado`);
							},
							autoClose: 1000 
						})    
					}
					
				} catch (error) {
					toast.error(JSON.stringify(error.message))
				} 
			}else{
				const response = await editFacturacionLizen(id,formValue,servicio,facturacion)
				
				try {
					
					if(response.status !== undefined && response.status === "error" ){
						toast.error(`Error en el sistema: ${JSON.stringify(response.message)}`)
					}else{
						
						
					 	toast.success("Se guardó exitosamente.",{
							onClose: () => {
								history.push(`/admin/servicios_lizen/listado`);
							},
							autoClose: 1000
						})    
					}
					
				} catch (error) {

					toast.error(JSON.stringify(error.message))
				} 
				 
			}  
			
		},
		enableReinitialize: isAddMode ? false : true
	})
	const estados = [
		{ key: 1, text: 'Activo', value: 'Activo' },
		{ key: 2, text: 'Legajo', value: 'Legajo' },
		{ key: 3, text: 'Facturado', value: 'Facturado' },
		{ key: 4, text: 'Abonado', value: 'Abonado' },
	]

	const handleUploadClickasd = async event => {
		var file = event.target.files[0];
		const formData = new FormData()
		
		if (file) {
			formData.append('file', file)
			const response_pdf_upload_factura = await fetchUploadFile(formData)
			formik.setFieldValue("billing_file_facturacion",response_pdf_upload_factura.data[0])
			
			setFileFacturacion(response_pdf_upload_factura.data[0])
		}
	};

	const handleUploadClienteFactClick = async event => {
		var file = event.target.files[0];
		const formData = new FormData()

		if (file) {
			formData.append('file', file)
			const response_pdf_upload_factura = await fetchUploadFile(formData)
			formik.setFieldValue("billing_file_fact_cliente",response_pdf_upload_factura.data[0])
			let fileResponse = await createLizenFactCliente(servicio.aseguradora.aseguradora,response_pdf_upload_factura.data[0])
			setFileFactCliente(fileResponse)
		}
	};
	const handleUploadClickLegajo =async event => {
		
		const formData = new FormData()
		let array_files = []
		let files = event.target.files
		
		Array.from(files).map(file=>{
			
			if (file) {
				formData.append('file', file)
	
				array_files.push(file.name)
			}
		})
		
		const response_pdf_upload_legajo = await fetchUploadFile(formData)
		
		if(!isAddMode){
			formik.setFieldValue("billing_file_legajo",response_pdf_upload_legajo.data)
			/* 
			La vista es editar: como ya cree el archivo, lo asocio a la entidad legajo
			*/
			const response_createLegajo = await createLizenLegajo(facturacion,response_pdf_upload_legajo.data)
			if(response_createLegajo.status === "sucess"){
				fetchLegajo()
			}
		}else{
			if(filesLegajo.length > 0){
				let array_new_legajos = filesLegajo
				response_pdf_upload_legajo.data.map(leg=>array_new_legajos.push(leg))
				
				
				setFilesLegajo(array_new_legajos)
				formik.setFieldValue("billing_file_legajo",array_new_legajos)
			}else{
				setFilesLegajo(response_pdf_upload_legajo.data)
				formik.setFieldValue("billing_file_legajo",response_pdf_upload_legajo.data)

			}
		}
	};

	const files_legajo_show = filesLegajo.map((legajo,i)=>{
		if(isAddMode){
			return(<List.Item>
				<a href={`${BASE_API}/${legajo}`} target="_blank">{legajo.split("/")[2].slice(32)}</a>
				<Button
					compact={true}  
					icon 
					basic 
					type="button" 
					target="_blank"
					circular
					size='mini'
					onClick={()=>handleClickDeleteCreateLegajo(legajo)}
				>
				<Icon name="delete" color='red'/>
				</Button>
			</List.Item>)
		}else{
			return(<List.Item>
				<a href={`${BASE_API}/${legajo.file_legajo}`} target="_blank">{legajo.file_legajo.split("/")[2].slice(32)}</a>
				<Button 
					
					compact={true}  
					icon 
					basic 
					type="button" 
					target="_blank"
					circular
					size='mini'
					onClick={()=>handleClickDeleteLegajo(legajo.id)}
				>
				<Icon name="delete" color='red'/>
				</Button>
			</List.Item>)
		}
	})
	const handleClick = event => {
    	hiddenFileInput.current.click();
  	};
	const handleClickFactCliente = event => {
		setContentModal(<p>¿Desea agregar un archivo?</p>)
		setTitleModal("Agregar archivo")
		setShowModal(true)
		setDataModal({'accion':'agregar','documento':'fact_cliente'})
		// hiddenFileInputFactCliente.current.click();
	};
	const handleClickDeleteLegajo = (id_legajo) => {
		setContentModal(<p>Desea eliminar este legajo ?</p>)
		setTitleModal("Eliminar archivo")
		setShowModal(true)
		setDataModal({'accion':'eliminar','documento':'legajo','id_legajo':id_legajo})
    	
  	};
	const handleClickDeleteCreateLegajo=(legajo)=>{
		let array_new_legajos = []
		array_new_legajos = filesLegajo.filter(e=>e !== legajo)
		setFilesLegajo(array_new_legajos)	
	}
	const handleClickDeletefactura=(factura)=>{
	
		setFileFacturacion(null)	
		formik.setFieldValue("billing_file_facturacion",null)
	}

	const handleClickDeleteFacturaCliente=(factura)=>{
		setFileFactCliente(null)
		formik.setFieldValue("billing_file_fact_cliente",null)
		setContentModal(<p>¿Desea eliminar esta factura?</p>)
		setTitleModal("Eliminar archivo")
		setShowModal(true)
		setDataModal({'accion':'eliminar','documento':'fact_cliente','id_factura':factura.id})

	}
	
  	const handleClickLegajo = event => {
		setContentModal(<p>Desea agregar un archivo ?</p>)
		setTitleModal("Agregar archivo")
		setShowModal(true)
		setDataModal({'accion':'agregar','documento':'legajo'})
    	
  	};

  	const openCloseModal = ()=>{
		
		setShowModal((prev)=>!prev)
	}
	const openConfirmModal = async(data_obj)=>{
	  if(data_obj.documento === "legajo") {
		if(data_obj.accion === "agregar"){
			setShowModal(false)
			hiddenFileInputLegajo.current.click();
		}

		if(data_obj.accion === "eliminar"){
			try {
				let response = await deleteLegajo(data_obj.id_legajo)

				toast.success(response)
				setShowModal(false)
				fetchLegajo()
			} catch (error) {
				console.log(error)
			}
		}
	  } else {
		if(data_obj.accion === "agregar"){
			setShowModal(false)
			hiddenFileInputFactCliente.current.click();
		}
		if(data_obj.accion === "eliminar"){
			try {
				let response = await deleteFactCliente(data_obj.id_factura)
				toast.success(response)
				setShowModal(false)
				fetchFactCliente()
			} catch (error) {
				console.log(error)
			}

		}
	  }
  	}
	
  return (
	<div>
    <Form  onSubmit={formik.handleSubmit}>
		<Segment.Group>
			<h4 style={{padding:'5px'}}><strong>Beneficiario: {servicio?.cliente?.nombre +" "+ servicio?.cliente?.apellido}</strong></h4>
		<Segment >
			<Grid>
				<Grid.Row >
					<Grid.Column computer={5} mobile={16} tablet={16}> 
						<h5>Estado</h5>
						<Dropdown
							clearable
							options={estados}    
							value={formik.values.servicio_estado}  	
							selection 
							fluid
							onChange={(_,data)=>formik.setFieldValue("servicio_estado",data.value)}
							
						/>

					</Grid.Column>
					<Grid.Column computer={5} mobile={16} tablet={16}>    
					<h5>Fecha de emisión</h5> 
							<Form.Input
								type="date"
								name="billing_fecha_emision"
								fluid 
								value={formik.values.billing_fecha_emision}
								onChange={(_,data)=>formik.setFieldValue("billing_fecha_emision",data.value)}
							/>   
						</Grid.Column>
					<Grid.Column computer={6} mobile={16} tablet={16}>
						</Grid.Column>
				</Grid.Row>
			</Grid>
		 	</Segment>
			
			<Segment color='blue'><strong>LEGAJO </strong></Segment>
			
				{filesLegajo.length > 0 && 
				
					(<Segment><p>
						Archivo(s) seleccionado(s)</p>
						<Label.Detail>{files_legajo_show[0]}</Label.Detail>
					</Segment>)
				}
			
			<Grid>
				<Grid.Row >
					
					<Grid.Column computer={16} mobile={16} tablet={16}>  
						<label /* htmlFor="ButtonProductUpFile"  */ className='dropzone'>
						<Header icon disabled>
							<Icon name='upload' circular/>
							
							</Header>
							<Button 
								type="button" 
								variant="contained" 
								color="primary" 
								component="span" 
								icon 
								onClick={handleClickLegajo}
							>
							Agregar documento(s)
								
							</Button>
								
						</label>
						<input
							className="input"
							accept="'image/jpg','image/jpeg', 'image/png', 'image/bmp', 'application/*'"
							id="ButtonProductUpFile"
							type="file"
							onChange={handleUploadClickLegajo}
							style={{display: 'none'}}
							ref={hiddenFileInputLegajo}
							multiple
						/>
						</Grid.Column>
							
				</Grid.Row>
			</Grid>
			
				
			
			<Segment color='blue'><strong>FACTURA </strong></Segment>
			
				{fileFacturacion !== null && 
					(<Segment >
						<p>
						Archivo seleccionado
						
						</p>	
						<Label.Detail>
						<a href={`${BASE_API}/${fileFacturacion}`} target="_blank">{fileFacturacion.split("/")[2].slice(32)}</a>
						<Button 
							
							compact={true}  
							icon 
							basic 
							
							target="_blank"
							circular
							size='mini'
							onClick={()=>handleClickDeletefactura(fileFacturacion)}
						>
						<Icon name="delete" color='red'/>
						</Button>
					
						</Label.Detail>
						</Segment>)
					}

			<Grid>
				<Grid.Row >
					
					<Grid.Column computer={16} mobile={16} tablet={16}>  
					
					
					<label /* htmlFor="ButtonProductUpFile" */ className='dropzone'>
							<Header icon disabled >
							<Icon name='upload' circular/>
							
							</Header>
							<Button 
								type="button" 
								variant="contained" 
								color="primary" 
								component="span" 
								icon 
								onClick={handleClick}
							>
							Agregar documento
								
							</Button>
						</label>
					 	<input
							className="input"
							accept="'image/jpg','image/jpeg', 'image/png', 'image/bmp', 'application/*'"
							id="ButtonProductUpFile"
							type="file"
							onChange={handleUploadClickasd}
							style={{display: 'none'}}
							ref={hiddenFileInput}
						/>
						
						
					</Grid.Column>
			
					
				</Grid.Row>
			</Grid>

			{getGroup(auth,"CEO") &&
				<>
					<Segment color='blue'><strong>FACTURA DIRECTO A CLIENTE</strong></Segment>

					{fileFactCliente?.url_archivo &&
						(<Segment >
							<p>
								Archivo seleccionado

							</p>
							<Label.Detail>
								<a href={`${BASE_API}/${fileFactCliente?.url_archivo}`} target="_blank">{fileFactCliente?.url_archivo?.split("/")[2]?.slice(32)}</a>
								<Button
									compact={true}
									icon
									basic
									target="_blank"
									circular
									size='mini'
									onClick={()=>handleClickDeleteFacturaCliente(fileFactCliente)}
								>
									<Icon name="delete" color='red'/>
								</Button>

							</Label.Detail>
						</Segment>)
					}



					<Grid>
						<Grid.Row >

							<Grid.Column computer={16} mobile={16} tablet={16}>


								<label /* htmlFor="ButtonProductUpFile" */ className='dropzone'>
									<Header icon disabled >
										<Icon name='upload' circular/>

									</Header>
									<Button
										type="button"
										variant="contained"
										color="primary"
										component="span"
										icon
										onClick={handleClickFactCliente}
									>
										Agregar documento

									</Button>
								</label>
								<input
									className="input"
									accept="'image/jpg','image/jpeg', 'image/png', 'image/bmp', 'application/*'"
									id="ButtonProductUpFile"
									type="file"
									onChange={handleUploadClienteFactClick}
									style={{display: 'none'}}
									ref={hiddenFileInputFactCliente}
								/>


							</Grid.Column>


						</Grid.Row>
					</Grid>
				</>
			}

			<Grid as={Segment}>
				
				<Grid.Column computer={2} mobile={16} tablet={16}> 
					<Button  type="submit"  fluid  content={"Guardar"} primary />
					
				</Grid.Column>
				<Grid.Column computer={2} mobile={16} tablet={16}> 
					
					<Button  fluid content="Volver" primary component={Link} href='/admin/servicios_lizen/listado'/>
				</Grid.Column>
				<Grid.Column computer={12} mobile={16} tablet={16}> </Grid.Column>
				
			</Grid>

  		</Segment.Group>
    </Form>
	{/*	TODO: LOGICA DE BORRADO DE FACTURAS DE CLIENTE IGUAL QUE LEGAJO */}
	<ModalBasic 
		show={showModal} 
		size="small" 
		title={titleModal} 
		children={contentModal}
		onClose={openCloseModal}
		onConfirm={openConfirmModal}
		dataModal={dataModal}
	/>
	 </div>
)
}



function initialValues(auth,servicio,billing){

	return {
		servicio_estado: servicio?.estado || "Activo",
		billing_fecha_emision: billing?.fecha_emision || null,
		operador:auth?.me?.id || "",
		billing_file_facturacion: billing?.file_factura || null,
		billing_file_legajo: billing?.billing_file_legajo || [] ,
		billing_file_fact_cliente: billing?.billing_file_fact_cliente || null,
	}

	
}


function validationSchema(){
	return {
		//servicio_estado: Yup.required(true),
		//billing_fecha_emision: Yup.required(true)
	}
}

