import React from 'react'
import config from '../../utils/config.json'
import { getPermissions } from '../../components/Common/Permissions'
import { useAuth } from '../../hooks/'
import "./HomeAdmin.scss"
import {Grid, Card, Icon,Image,Label,Avatar } from 'semantic-ui-react'
export  function HomeAdmin() {
  const {auth} = useAuth()
  return (
    <Card.Group>
       <Card 
     
        href="/admin" 
        className='cardPadre'
      >
    <h4 className='cardContent' > 
      <span className='avatar'><Icon  name="home"/></span>
      <span className="nameCard">Dashboard</span> 
      <span className="icon"><Icon  name="arrow right"/></span>
    </h4>
    </Card>
      {config.cards.map((item,i)=>{
      const active = getPermissions(item.permissionName,auth)
      return active && item.active &&
      (  <Card 
     
        href={item.routing} 
        className='cardPadre'
      >
    <h4 className='cardContent' > 
      <span className='avatar'><Icon  name={item.icon}/></span>
      <span className="nameCard">{item.name}</span> 
      <span className="icon"><Icon  name="arrow right"/></span>
    </h4>
    </Card>)
      }) }
   
    
 </Card.Group>
  )
}
